<template>
	<div class="pui-form">
		<v-form @submit.prevent class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
			<div row wrap class="pui-form-layout">
				<pui-field-set :title="$t('aqvvesseltime.title')">
					<v-layout wrap>
						<v-flex xs12 md8>
							<pui-text-field :label="$t('aqvvesseltime.name')" v-model="model.name" required toplabel maxlength="150"></pui-text-field>
						</v-flex>
						<v-flex xs12 md4>
							<pui-text-field
								:label="$t('aqvvesseltime.acronym')"
								v-model="model.acronym"
								required
								toplabel
								maxlength="5"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md4>
							<pui-select
								attach="aqversiontimeid"
								:label="this.$t('aqvvesseltime.versiontime')"
								:placeholder="this.$t('aqvvesseltime.phversiontime')"
								toplabel
								clearable
								required
								v-model="model"
								modelName="aqversiontime"
								:modelFormMapping="{ aqversiontimeid: 'versiontime' }"
								:itemsToSelect="versiontimeItemsToSelect"
								itemValue="aqversiontimeid"
								itemText="name"
							>
							</pui-select>
						</v-flex>
						<v-flex xs12 md4>
							<pui-select
								attach="aqvesseltypesid"
								:label="this.$t('aqvvesseltime.vesseltype')"
								:placeholder="this.$t('aqvvesseltime.phvesseltype')"
								toplabel
								clearable
								required
								v-model="model"
								modelName="aqvesseltypes"
								:modelFormMapping="{ aqvesseltypesid: 'vesseltype' }"
								:itemsToSelect="vesseltypeItemsToSelect"
								itemValue="aqvesseltypesid"
								itemText="name"
							>
							</pui-select>
						</v-flex>
						<v-flex xs12 md4>
							<pui-text-field
								:label="$t('aqvvesseltime.timehot')"
								v-model="model.timehot"
								required
								toplabel
								maxlength="5"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md6>
							<pui-text-field
								:label="$t('aqvvesseltime.timemandockoutside')"
								v-model="model.timemandockoutside"
								required
								toplabel
								maxlength="5"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md6>
							<pui-text-field
								:label="$t('aqvvesseltime.timemanundockoutside')"
								v-model="model.timemanundockoutside"
								required
								toplabel
								maxlength="5"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md6>
							<pui-text-field
								:label="$t('aqvvesseltime.timemandockchange')"
								v-model="model.timemandockchange"
								required
								toplabel
								maxlength="5"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md6>
							<pui-text-field
								:label="$t('aqvvesseltime.timemanundockchange')"
								v-model="model.timemanundockchange"
								required
								toplabel
								maxlength="5"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md12>
							<pui-text-area
								v-model="model.description"
								:label="$t('aqvvesseltime.description')"
								maxlength="250"
								toplabel
							></pui-text-area>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 md4>
							<pui-checkbox
								:label="$t('aqvvesseltime.disabled')"
								v-model="model.disabled"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-flex>
					</v-layout>
				</pui-field-set>
			</div>
			<!-- footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
export default {
	name: 'aqvvesseltimeform',
	mixins: [PuiFormMethodsMixin],

	data() {
		return {
			modelName: 'aqvvesseltime',
			model: 'aqvvesseltime'
		};
	},
	computed: {
		vesseltypeItemsToSelect() {
			return [{ aqvesseltypesid: this.model.vesseltypesid }];
		},

		versiontimeItemsToSelect() {
			return [{ aqveriontimerid: this.model.aqversiontimeid }];
		}
	},
	beforeCreate() {
		if (this.model) {
			this.modelLoaded = true;
		}
	},
	methods: {}
};
</script>
